.hero {
  background-color: var(--overlay);
  color: #fff;
  height: 100vh;
  width: 100%;
  position: relative;
  opacity: 0.9;
}

.hero:before {
  content: "";
  background: url("../../images/mainImg.jpg") no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
}

.hero .content p:first-child {
  font-style: italic;
  font-size: 0.9rem;
}

.hero .content p:nth-child(2) {
  font-size: 1.9rem;
}

.hero .content p:nth-child(3) {
  font-size: 5rem;
  font-weight: 700;
}

.hero .content p:nth-child(4) {
  font-size: 2.2rem;
  font-style: italic;
}


@media screen and (max-width: 940px) {
  .hero .content {
    padding-left: 25px;
  }
  .hero .content p:nth-child(3) {
    font-size: 2rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 1.6rem;
  }
}
