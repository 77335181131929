.demo {
  padding: 9rem 4rem;
  width: 100%;
  background-color: #f2f2f2;
}

.demo h2 {
  font-size: 3rem;
  text-align: center;
  position: relative;
  bottom: 5rem;
}

.demo span {
  position: relative;
  bottom: 5rem;
}

.demo .container {
  width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.demo .col-1 {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.icons {
  display: flex;
  justify-content: start;
  align-items: center;
}

.contact-text {
  font-size: 1rem !important;
  font-style: normal !important;
  margin-left: 1rem !important;
  font-weight: 400 !important;
  transform: translateY(-3px);
}

.demo .col-1 p:nth-child(1) {
  font-size: 1.8rem;
  font-style: italic;
}

.demo .col-1 p:nth-child(2) {
  font-size: 3rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.demo .col-1 p:nth-child(3) {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.demo .col-2 {
  margin: auto;
}

@media screen and (max-width: 940px) {
  .demo .container {
    max-width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .demo {
    padding: 10px;
    padding-bottom: 40px;
  }
  .demo .col-2 {
    width: 100%;
  }

  .demo iframe {
    width: 100%;
    height: 250px;
  }
  .demo h2 {
    margin-top: 30px;
    font-size: 3rem;
    text-align: center;
    position: relative;
    bottom: 0;
    font-size: 2rem;
  }
  .demo span {
    position: relative;
    bottom: 0;
  }

  .demo .col-1 p:nth-child(1) {
    font-size: 1.2rem;
    font-weight: bold;
    font-style: italic;
  }

  .demo .col-1 p:nth-child(2) {
    font-size: 2rem;
    font-weight: 600;
    margin: 0.5rem 0;
  }

  .demo .col-1 p:nth-child(3) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
