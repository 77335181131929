.about {
  background-color: #f8f8f8;
  margin: auto;
  padding: 3rem 0 3rem 0;
  width: 100%;
}

.about .container {
  display: flex;
  justify-content: center;
}

.about h2 {
  font-size: 3rem;
}

.about .col-2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  font-family: "Roboto Condensed", sans-serif;
}

.about .col-2 p:first-of-type {
  margin: 2rem 0;
}

.about .col-2 p:nth-child(4) {
  font-size: 1.8rem;
  font-style: italic;
  font-weight: 600;
}

.about button {
  margin-top: 1rem;
}

@media screen and (max-width: 940px) {
  .about .container {
    width: 100%;
  }

  .about .container img {
    padding: 0rem;
    width: 90vw;
  }

  .about .container .col-2 {
    padding: 0;
  }

  .about button {
    margin-bottom: 1rem;
  }
  .about h2 {
    font-size: 2rem;
  }

  .about .col-2 {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
  }

  .about .col-2 p:first-of-type {
    margin: 2rem 0;
    font-size: 1rem;
  }

  .about .col-2 p:nth-child(4) {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 600;
  }
}
