.footer {
  width: 100%;
  background-color: #000;
  color: #eee;
  padding: 3rem 1rem;
  z-index: 21;
  position: relative;
}

.footer .container {
  max-width: 1240px;
  margin: auto;
  text-align: center;
}

.footer ul {
  display: flex;
  justify-content: center;
}

.footer-a {
  color: white;
}

.footer-a:hover {
  color: orange;
}

.footer li {
  padding: 1rem;
}

.footer li a {
  color: #fff;
}

.footer li a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.bottom {
  text-align: center;
  padding-top: 0.5rem;
}

.line {
  margin-bottom: 1rem;
}

@media (max-width: 940px) {
  .footer {
    font-size: 14px;
    width: 100%;
  }
}
