.productsNav {
  padding: 120px 50px;
  display: flex;
  background-color: #e6e6e6;
}

.productList {
  padding: 10px;
  width: 20%;
  font-size: 1rem;
  margin-right: 1rem;
  border-radius: 10px;
  background-color: white;
}

.productList a {
  text-transform: capitalize;
  color: black;
  font-weight: 600;
}
.productList a:hover {
  color: darkred;
}

.productList li {
  padding: 0.5rem;
}

.productInfo {
  width: 80%;
  background-color: white;
  padding: 1rem 3rem;
  border-radius: 10px;
}
hr {
  margin-top: 3px;
}

@media (max-width: 900px) {
  .productsNav {
    flex-direction: column;
  }
  .productInfo,
  .productList {
    width: 100%;
  }
  .productList {
    margin-bottom: 2rem;
  }
}
@media (max-width: 650px) {
  .productsNav {
    padding: 120px 10px;
  }
  .productInfo {
    padding: 1rem 1rem;
  }
}
