@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

:root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #f5a623;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, 0.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}

.up-btn {
  display: none;
  position: fixed;
  width: 3rem;
  height: 3rem;
  right: 5%;
  bottom: 25px;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  cursor: pointer;
  z-index: 19;
}
.icon-up {
  font-size: 2rem;
  color: orange;
}

@media (max-width: 940px) {
  .body {
    font-size: 12px;
  }
  .up-btn {
    right: 30px;
  }
}
