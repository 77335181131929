@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Cutive&family=Roboto:ital,wght@0,300;1,100;1,300&family=Sacramento&display=swap");

.header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  transition: 0.3s ease-in;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  transition: all 0.3s ease-in-out;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.products-header {
  background-color: black;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.header .nav-menu a {
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
}
.products-header .nav-menu a {
  color: white;
}

.header.active {
  background-image: linear-gradient(to right, #fff, #fff);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80px;
}

.header.active .nav-menu a {
  color: black;
}

h1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 25px;
  font-family: Cutive;
  letter-spacing: 1px;
  bottom: 3px;
}

h1 .span-one {
  position: relative;
  top: 8px;
}

h1 .span-two {
  position: absolute;
  height: 3px;
  width: 3rem;
  background-color: #f5a623;
  right: -7px;
  bottom: 5px;
}

.header.active h1 {
  color: black;
}

.header .navbar img {
  position: relative;
  top: 3px;
  left: 5px;
  width: 150px;
  height: auto;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding: 1rem;
  font-weight: 500;
}

.nav-item .active {
  padding-bottom: 8px;
  border-bottom: 3px solid var(--secondary-color);
}

.header .nav-item a:hover {
  padding-bottom: 8px;
  border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .header {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .header .navbar {
    max-width: 100%;
  }
  h1 {
    position: relative;
    right: 15px;
    letter-spacing: 0;
    font-size: 20px;
  }

  h1 .span-two {
    position: absolute;
    height: 2px;
    width: 2.5rem;
    background-color: #f5a623;
    right: -7px;
    bottom: -4px;
  }

  .hamburger {
    display: block;
  }
  .hamburger .fa {
    color: white;
  }

  .hamburger.active .fa {
    color: black;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 80px;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }
  .header.active .nav-menu a {
    color: #ffffff;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 125px;
  }
  .products-header {
    background-color: black;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.7);
  }
}
