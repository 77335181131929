.testimonials {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
}

.testimonials .container {
  width: 1240px;
  margin: 1rem auto;
  padding: 4rem 0rem;
}

.testimonials h2 {
  font-size: 3rem;
  text-align: center;
}

.testimonials .container .content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 4rem 0;
}

@media screen and (max-width: 940px) {
  .testimonials .container {
    width: 100%;
    padding: 2rem 0rem;
  }
  .testimonials .container .content {
    margin: 2rem 0;
  }

  .testimonials .container .content {
    grid-template-columns: 1fr;
  }

  .testimonials .card {
    max-width: 100%;
    margin: 1rem;
  }
  .testimonials h2 {
    font-size: 2rem;
    text-align: center;
  }
}
