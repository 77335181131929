.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
  padding: 1rem;
  height: 20rem;
  position: relative;
  z-index: 2;
  transition: all 0.1s ease-in;
  background-position: center;
  background-size: cover;
}
.card:hover {
  cursor: pointer;
  scale: 1.015;
}

.card p {
  color: #fff;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin: 12px;
  z-index: 3;
  text-align: center;
}

.sub-div {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.card span {
  background-color: orange;
  height: 0.2rem;
  z-index: 3;
  width: 4rem;
}
